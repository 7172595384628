<template>
  <div class="config-app">
    <h2 class="app-title">Definições dos Clientes</h2>
    <p class="subtitle">Permite configurar o comportamento do sistema para os Clientes.</p>

    <app-tab :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <div v-if="active === 'arrematante'" class="app-input-container">
        <app-label-wrapper label="Aviso Administrador de Cadastro">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('arrematante.cadastro.informa-leiloeiro').value" />
          <p class="app-legend m-t-xs"><strong>Avisar por e-mail</strong> o administrador da empresa sempre que um novo arrematante se cadastrar <u-icon name="email" style="font-size: 16px" /> </p>
        </app-label-wrapper>

        <app-label-wrapper label="Layout/Modo do Cadastro">
          <!--<u-select :options="[{label: 'Padrão', value: 'v1'},{label: 'Formulário Único', value: 'v2'}]" class="app-input" hide-underline v-model="c('arrematante.modoCadastro').value" />-->
          <u-select disable :options="[{label: 'Padrão', value: 'v1'},{label: 'Formulário Único', value: 'v2'}]" class="app-input" hide-underline v-model="c('arrematante.modoCadastro').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Permitir cadastro de estrangeiros">
          <u-select :options="[{label: 'Não', value: '0'},{label: 'Sim', value: '1'}]" class="app-input" hide-underline v-model="c('arrematante.permitirEstrangeiros').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Bloquear cadastro de pessoa jurídica por não logados">
          <u-select :options="[{label: 'Padrão, não bloquear', value: '0'},{label: 'Bloquear, liberar cadastro somente por representante legal', value: '1'}]" class="app-input" hide-underline v-model="c('arrematante.bloquearCadPj').value" />
          <p class="app-legend m-t-xs"><strong>Se bloqueado</strong>, somente será permitido o cadastro de pessoa jurídica por um representante legal, pessoa física, devidamente cadastrada.</p>
        </app-label-wrapper>

        <app-label-wrapper label="Forçar Envio de Documentos para Completar o Cadastro">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('arrematante.exigirDocsCadastro').value" />
          <p class="app-legend m-t-xs"><strong>Se ativo</strong>, o arrematante somente conseguirá concluir o cadastro após o envio dos documentos obrigatórios</p>
        </app-label-wrapper>

        <app-label-wrapper label="Exigir a Liberação individual do cadastro para lances em Sucata">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('arrematante.exigirLiberacaoCadastroComprarSucata').value" />
          <p class="app-legend m-t-xs"><strong>Se sim</strong>, o arrematante somente conseguirá efetuar lances em sucata se em seu cadastro o checkbox "Pode comprar sucata" estiver marcado.</p>
        </app-label-wrapper>

        <app-label-wrapper label="Validar e-mail no cadastro">
          <u-select :options="[{label: 'Desativado', value: '0'},{label: 'Depois o cadastro', value: '2'},{label: 'Durante o cadastro', value: '1'}]" class="app-input" hide-underline v-model="c('cliente.confirmarEmailCadastro').value" />
        </app-label-wrapper>

        <app-label-wrapper label="Exigir Selfie" :disabled="!config.hasService('selfie')">
          <u-select :options="[{label: 'Desativado', value: '0'}, {label: 'Ativado', value: '1', disable: false}]" class="app-input" hide-underline v-model="c('arrematante.exigirSelfie').value" />
          <p class="app-legend m-t-xs m-b-none"><strong>Tarifa será aplicada</strong>. Para ativar a opção, o produto selfie deve ser contratado.</p>
        </app-label-wrapper>

        <app-label-wrapper label="Validação por Biometria Facial + Documento com Foto" :disabled="!config.hasService('biometria-facial')">
          <u-select :options="[{label: 'Desativado', value: '0'}, {label: 'Ativado', value: '1', disable: false}]" class="app-input" hide-underline v-model="c('arrematante.biometriaFacial').value" />
          <p class="app-legend m-t-xs m-b-none"><strong>Tarifa será aplicada</strong>. Ao ativar a opção, verifique com seu gerente de contas seu plano de Biometria Facial e Validação de documentos</p>
        </app-label-wrapper>

        <app-label-wrapper label="Validar celular">
          <u-select :options="[{label: 'Desativado', value: '0'}, {label: 'Via Ligação', value: '2', disable: true},{label: 'Via SMS', value: '1', disable: true},{label: 'Via Whatsapp', value: '1', disable: true}]" class="app-input" hide-underline v-model="c('cliente.confirmarTelefoneCadastro').value" />
          <p class="app-legend m-t-xs m-b-none"><strong>Tarifa será aplicada</strong>. Ao ativar a opção, verifique com seu gerente de contas seu plano de dados SMS</p>
        </app-label-wrapper>

        <app-label-wrapper label="Habilitar Contrato de Adesão">
          <u-select :options="[{label: 'Desativado', value: '0'}, {label: 'Ativado', value: '1'}]" class="app-input" hide-underline v-model="c('arrematante.habilitarContratoAdesao').value" />
          <p class="app-legend m-t-xs"><strong>Se ativo</strong>, o arrematante precisa aceitar o contrato de adesão (template ID arrematante.contrato.adesao), e o mesmo será enviado por e-mail após o cadastro</p>
        </app-label-wrapper>

        <app-label-wrapper label="Forçar Análise de Novos Cadastros">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('arrematante.cadastro.forcar-analise').value" />
          <p class="app-legend m-t-xs"><strong class="text-negative">Não recomendado</strong> Força o envio para análise de todos os novos clientes cadastrados, mesmo que não tenha enviado os documentos obrigatórios</p>
        </app-label-wrapper>

        <app-label-wrapper label="API para consulta de CPF">
          <u-select :options="[{label: 'Nenhum', value: ''},{label: 'Serpro', value: 'serpro'}]" class="app-input" hide-underline v-model="c('pessoa.apiConsultaCPF').value" />
<!--          <p class="app-legend m-t-xs"></p>-->
        </app-label-wrapper>
        <app-label-wrapper label="Credenciais para API para consulta de CPF">
          <collapse title="Editar credenciais CPF" :collapse="false">
            <code-editor v-model="c('pessoa.apiConsultaCPFCredenciais').value" :languages="[['javascript']]" :display_language="false" class="source-code" width="100%" height="200px" font_size="14px" :wrap_code="true" :spellcheck="false"></code-editor>
          </collapse>
        </app-label-wrapper>

        <app-label-wrapper label="API para consulta de CNPJ">
          <u-select :options="[{label: 'Nenhum', value: ''},{label: 'Brasil API - Gratuito', value: 'brasil-api'}]" class="app-input" hide-underline v-model="c('pessoa.apiConsultaCNPJ').value" />
<!--          <p class="app-legend m-t-xs"></p>-->
        </app-label-wrapper>
        <app-label-wrapper label="Credenciais para API para consulta de CNPJ">
          <collapse title="Editar credenciais CNPJ" :collapse="false">
            <code-editor v-model="c('pessoa.apiConsultaCNPJCredenciais').value" :languages="[['javascript']]" :display_language="false" class="source-code" width="100%" height="200px" font_size="14px" :wrap_code="true" :spellcheck="false"></code-editor>
          </collapse>
        </app-label-wrapper>

        <hr class="m-b-lg" />

        <app-label-wrapper label="Anexar arquivos no e-mail na formalização de venda">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('arrematante.formalizacao.anexarDocs').value" />
          <p class="app-legend m-t-xs"><strong class="text-negative">Não recomendado</strong> Ao permitir, os documentos da formalização serão enviados por e-mail, o que impede o rastreio de abertura e download dos mesmos.</p>
        </app-label-wrapper>
      </div>
      <div v-if="active === 'avancado'" class="app-input-container">
        <app-label-wrapper label="Folha de estilo (CSS) personalizada para o painel">
          <textarea rows="10" v-model="c('painel.css').value" class="sl-textarea">
          </textarea>
        </app-label-wrapper>

        <app-label-wrapper label="Embutir código (Scripts/Javascript) personalizada para o painel">
          <textarea rows="10" v-model="c('painel.js').value" class="sl-textarea">
          </textarea>
        </app-label-wrapper>

        <app-label-wrapper label="Embutir código (HTML) personalizada para o painel">
          <textarea rows="10" v-model="c('painel.html').value" class="sl-textarea">
          </textarea>
        </app-label-wrapper>

        <app-label-wrapper label="Favicon do painel">
          <u-input hide-underline v-model="c('painel.favicon').value" class="app-input" />
        </app-label-wrapper>

        <app-label-wrapper label="Webhook de Cadastro">
          <p class="app-legend m-t-xs m-b-none"><strong>Uma URL por linha</strong>. Se existir URLs, o sistema irá enviar via JSON todos os dados do cadastro sempre que um novo cliente se cadastrar.</p>
          <textarea rows="10" v-model="c('clientes.webhook.urls').value" class="sl-textarea">
          </textarea>
          <div class="m-t">
            <div class="m-b-xs">Testar:</div>
            <div class="flex items-center">
              <erp-input placeholder="ID de algum arrematante" size="2" v-model="fake.arrematanteId" style="max-width: 170px"/> <e-btn @click="testarWebhook" label="Testar" class="m-l" />
            </div>
          </div>
        </app-label-wrapper>
      </div>
      <app-label-wrapper>
        <div class="m-t">
          <u-btn @click="$emit('salvar')" label="Salvar configurações" no-caps color="green" class="app-btn" />
        </div>
      </app-label-wrapper>
    </app-tab>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import {USelect} from "uloc-vue"
import CodeEditor from 'simple-code-editor'
import Collapse from 'components/globalconfig/components/include/Collapse'
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import {webhookCall} from "@/domain/arrematante/services"

export default {
  name: "ConfigArrematante",
  mixins: [AppMixin],
  components: {
    ErpInput,
    Collapse,
    USelect,
    CodeEditor
  },
  data() {
    return {
      active: 'arrematante',
      fake: {
        arrematanteId: null
      }
    }
  },
  computed: {
    menu() {
      return [
        {
          name: 'arrematante',
          label: 'Arrematante'
        },
        {
          name: 'avancado',
          label: 'Avançado'
        }
      ]
    }
  },
  methods: {
    testarWebhook () {
      this.$uloc.loading.show('Transmitindo dados')
      webhookCall(this.fake.arrematanteId)
          .then(response => {
            this.$uloc.loading.hide()
            console.log(response.data)
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    }
  }
}
</script>
